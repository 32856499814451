import React from "react";
import { Redirect } from "react-router-dom";

import Login from "../pages/Login";

import AdminsList from "../pages/dashboard/admins/AdminsList";
import AddAdmin from "../pages/dashboard/admins/AddAdmin";
import EditAdmin from "../pages/dashboard/admins/EditAdmin";
import EditProfile from "../pages/dashboard/admins/EditProfile";
import RolesList from "../pages/dashboard/roles/RolesList";
import AddRole from "../pages/dashboard/roles/AddRole";
import EditRole from "../pages/dashboard/roles/EditRole";
import EditEvent from "../pages/dashboard/events/EditEvent";
import AddEvent from "../pages/dashboard/events/AddEvent";
import EventsList from "../pages/dashboard/events/EventsList";
import Home from "../pages/front/Home";


const adminRoutes = [
  {
    path: "/admins",
    component: AdminsList,
  },
  // {
  //   path: "/my-admins",
  //   component: MyAdminsList,
  // },
  {
    path: "/add-admin",
    component: AddAdmin,
  },
  {
    path: "/edit-admin/:id",
    component: EditAdmin,
  },
  // {
  //   path: "/get-admin/:id",
  // },
  { path: "/edit-profile", component: EditProfile },
  { path: "/roles", component: RolesList, },
  {
    path: "/add-role",
    component: AddRole,
    // permission: PERMISSIONS.ROLE_CREATE,
  },
  {
    path: "/edit-role/:id",
    component: EditRole,
    // permission: PERMISSIONS.ROLE_EDIT,
  },
]

const authProtectedRoutes = [



  ...adminRoutes,
 
  { path: "/events", component: EventsList, },
  {
    path: "/add-event",
    component: AddEvent,
    // permission: PERMISSIONS.ROLE_CREATE,
  },
  {
    path: "/edit-event/:id",
    component: EditEvent,
    // permission: PERMISSIONS.ROLE_EDIT,
  },

  { path: "/", exact: true, component: () => <Redirect to="/events" /> },
];

const publicRoutes = [{ path: "/login", component: Login }, { path: "/", component: Home }];

export { authProtectedRoutes, publicRoutes };
