import { GoogleMap, Marker, useJsApiLoader, Autocomplete, InfoWindow } from "@react-google-maps/api";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { getEvents } from "../../api/front";



export default function Home(){

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_MAPS_API,
        libraries: ["places"]
    })

    const [events, setEvents] = useState([])

      useEffect(() => {
        getEvents().then(res => {
            setEvents(res.data.events)
        })
      }, [])

      const [activeEvent, setActiveEvent] = useState(null)

    return (
        <div>

            {isLoaded &&  <GoogleMap 
                mapContainerStyle={{
                    width: '100%',
                    height: '100vh'
                }}
                zoom={10}
                center={{lat: 30.04330573274441, lng: 31.221433222634705}}
               
            >

                {events.map(ev => {
                    return <Marker onClick={() => setActiveEvent(ev.id)} key={ev.id} position={{lat: parseFloat(ev.lat), lng: parseFloat(ev.long)}}>

                        {activeEvent == ev.id && <InfoWindow onCloseClick={() => setActiveEvent(null)}>
                            <div>
                                <img src={ev.image_url} style={{height: '170px'}} />
                                <h4 style={{marginTop: "10px", marginBottom: "5px", fontSize: "17px"}}>{ev.name}</h4>
                                <p style={{marginBottom: "0px", fontSize: "14px", maxWidth: "200px"}}>{ev.description}</p>
                                  
                            </div>
                        </InfoWindow>}
                    </Marker>
                })}
            </GoogleMap>}
        </div>
    )
}