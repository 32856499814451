import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import DeleteModal from "../../../components/Common/DeleteModal"
import { usePagination } from "../../../hooks/usePagination"
import ResultHandler from "../../../components/ResultHandler"
import PaginationHandler from "../../../components/PaginationHandler"
import { deleteEvent, getEvents, searchAndExport, importFile, importEvents } from "../../../api/events"
import TableHeaderHandler from "../../../components/TableHeaderHandler"
import { useTranslation } from "react-i18next"
import { useResponse } from "../../../hooks/useResponse"
import TableAction from "../../../components/TableAction"
import EmptyData from "../../../components/EmptyData"
import { Table, Thead, Tbody, Td, Tr, Th } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Accordion, AccordionHeader, AccordionBody, AccordionItem, Button, Col, Modal, ModalBody, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from "reactstrap"

import { PERMISSIONS } from "../../../api/permissions";
import ImportModal from "../../../components/ImportModal"


const parseAction = ({ action, admin }) => {
  return `${admin.username} has ${action}${(action[action.length - 1] === "e") ? "d" : "ed"} an event (${admin.role.name})`
}
const getClassByEventHistoryAction = (action) => {
  switch (action) {
    case "import": { return "badge bg-info" }
    case "create": { return "badge bg-success" }
    case "edit": { return "badge bg-warning" }
  }
}

export default function EventsTable() {

  const { t } = useTranslation()


  const [filter, setFilter] = useState({
    type: "group",
    relation: "AND",
    children: [
      {
        type: "condition",
        field: "",
        value: "",
        operator: "like"
      }
    ]
  })

  const {
    response,
    loading,
    error,
    setPage,
    page,
    setPerPage,
    totalPages,
    setPayload,
    setMeta
  } = usePagination(getEvents)

  const [filters, setFilters] = useState({
    name: "",
    email: ""
  })


  //// delete 
  const [show, setShow] = useState(false)
  const [deletingEvent, setDeletingEvent] = useState(null)

  const { setData } = useResponse(deleteEvent, () => {
    setShow(false)
    setDeletingEvent(null)
    setPayload({})
  })

  function onDeleteClick() {
    setData({ type: "add", data: deletingEvent })
  }


  function onCloseClick() {
    setShow(false)
  }
  

  ///// import

  const [importModalOpen, setImportModalOpen] = useState(false)

  //// assign

  const [selectedEvent, setSelectedEvent] = useState(null)

  return (
    <>
      <TableHeaderHandler
        permission={PERMISSIONS.EMPLOYEE_CREATE}
        setPage={setPage}
        setPerPage={setPerPage}
        addUrl="/add-event"
      >
       
      </TableHeaderHandler>
   
      <div className="table-rep-plugin">
        <div className="table-responsive">
          <Table className="table table-striped table-bordered">
            <Thead className="table-light table-nowrap">
              <Tr>
                <Th>Name</Th>
                <Th>Description</Th>
                <Th style={{ width: "15%" }}>{t("tables.Action")}</Th>
              </Tr>
            </Thead>

            {response.data && !loading && (
              <Tbody>
                {response.data.events.map(row => {
                  return (
                    <Tr key={row.id}>
                      <Td>
                        {row.name}
                      </Td>
                      <Td>{row.description}</Td>
                      <Td>
                        <TableAction
                          hasEdit={true}
                          hasDelete={true}
                          editPermission={PERMISSIONS.EMPLOYEE_EDIT}
                          deletePermission={PERMISSIONS.EMPLOYEE_DELETE}
                          type="event"
                          id={row.id}
                          setShow={setShow}
                          setDeleteIntitiy={setDeletingEvent}
                        />
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            )}
          </Table>

          {!loading &&
            <EmptyData empty={response.data && response.data.events.length === 0} msg="No Events Found." />
          }
          <DeleteModal
            show={show}
            onDeleteClick={onDeleteClick}
            onCloseClick={onCloseClick}
          />


          {response.data && !loading && (
            <PaginationHandler
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}


        <ImportModal 
          isOpen={importModalOpen} 
          onCloseClick={() => {setImportModalOpen(false)}} 
          api={importEvents} 
          handleRefresh={() => setPayload({refresh: true})}
          fileName="erp_import_events_template.xlsx"
        />
          
        
          <ResultHandler error={error} loading={loading} />

        </div>
      </div>
    </>
  )
}
