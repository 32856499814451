export const PERMISSIONS = {
  ADMIN_VIEW: 'permission-admin-view',
  ADMIN_EDIT: 'permission-admin-edit',
  ADMIN_DELETE: 'permission-admin-delete',
  ADMIN_CREATE: 'permission-admin-create',

  EMPLOYEE_VIEW: 'permission-employee-view',
  EMPLOYEE_EDIT: 'permission-employee-edit',
  EMPLOYEE_DELETE: 'permission-employee-delete',
  EMPLOYEE_CREATE: 'permission-employee-create',
  EMPLOYEE_IMPORT: 'permission-employee-import',

  CONTRACTS_VIEW: 'permission-company-contract-view-assigned',

  
  COMPANY_VIEW: 'permission-company-view',
  COMPANY_EDIT: 'permission-company-edit',
  COMPANY_DELETE: 'permission-company-delete',
  COMPANY_CREATE: 'permission-company-create',
  COMPANY_IMPORT: 'permission-company-import',

  POULE_VIEW: 'permission-pool-view',
  POULE_EDIT: 'permission-pool-edit',
  POULE_DELETE: 'permission-pool-delete',
  POULE_CREATE: 'permission-pool-create',
  POULE_IMPORT: 'permission-pool-import',


  ROLE_VIEW: 'permission-role-view',
  ROLE_EDIT: 'permission-role-edit',
  ROLE_DELETE: 'permission-role-delete',
  ROLE_CREATE: 'permission-role-create',


  
}
