// import FileManagerModal from "../pages/dashboard/file-manager/FileManagerModal"
import React, { useState } from "react"
import { FormFeedback, Input, Label } from "reactstrap"
import { useTranslation } from "react-i18next"

import "./ChooseImage.css"

export default function ChooseImage(props) {
  const [open, setOpen] = useState(false)

  function removeImage() {
    if (!props.disabled) {
      props.handleChooseImage(null)
    }
  }

  const { t } = useTranslation()

  return (
    <>
      <Label
        style={{
          display: props.hideLabel ? "none" : "block",
          marginBottom: "10px",
        }}
      >
        {props.label ? t(props.label) : t("forms.Image")}
      </Label>

      {props.error ? (
        <FormFeedback style={{display:"block"}} type="invalid">{props.error}</FormFeedback>
      ) : null}

      <div
        className={props.error ? "ChooseImage invalid" : "ChooseImage"}
        style={{ height: props.height ? props.height : "200px" }}
      >
        {!props.image && <div>+</div>}
        {props.image && (
          <>
            <img
              style={{ height: "100%", width: "100%", objectFit: "contain" }}
              src={props.image}
            />

            <i
              onClick={removeImage}
              style={{
                position: "absolute",
                top: "15px",
                right: "20px",
                fontSize: "20px",
                cursor: "pointer",
                display: props.hideRemoveIcon ? "none" : "block",
                zIndex: 9
              }}
              className="mdi mdi-close"
            ></i>
          </>
        )}
        <input 
          type="file" 
          style={{position: "absolute", opacity: "0", top: "0px", left: "0px", width: "100%", height: "100%",}} 
          disabled={props.disabled} 
          className="form-control" 
          onChange={e => props.handleChooseImage(e.target.files[0])} 
        />
      </div>
    </>
  )
}
