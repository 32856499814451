import { myAxios } from "./axios"

export function getEvents(data, { page, perPage }) {
    let config = {
        params: {
            page,
            perPage,
            ...(data || {})

        },
    }
    return myAxios.get("/events", config)
}

export function searchAndExport(data) {
    let config = {
        params: {
            filter: data
        },
    }
    return myAxios.get("/events/export", config)
}

export function getEvent(id) {
    return myAxios.get("/events/" + id)
}

export function addEvent(data) {
    return myAxios.post("/events/create", data)
}

export function editEvent(id, data) {
    return myAxios.put("/events/" + id + "/update", data)
}

export function deleteEvent(id) {
    return myAxios.delete("/events/" + id + "/delete")
}

export function importEvents(data) {
    return myAxios.post("/events/import", data)
}

export function assignEventToAdmin({data, empId}) {
    return myAxios.put('/events/'+empId+'/admins/assign',data)
}