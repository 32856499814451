import i18next from "i18next"
import * as Yup from "yup"
import React from "react"
import { useTranslation } from "react-i18next"

const slugReg = new RegExp("^[A-Za-z\u0621-\u064A-]*$")
const passwordReg = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
)



export default function useCustomValidation() {

  const { t } = useTranslation()

  const strongPasswordMsg = t("validation.strongPass")

  const loginSchema = Yup.object({
    email: Yup.string().required(t("validation.email")).nullable(),
    password: Yup.string().required(t("validation.password")).nullable(),
  })

  const addAdminSchema = Yup.object({
    username: Yup.string().required(t("validation.username")),
    email: Yup.string().required(t("validation.email")),
    language: Yup.string(),

    password: Yup.string()
      .required(t("validation.password")),
      //.matches(passwordReg, strongPasswordMsg),
    confirmPassword: Yup.string()
      .required(t("validation.confirmPass"))
      .oneOf([Yup.ref("password"), null], t("validation.confirmPass")),
    categories: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.number(),
          label: Yup.string(),
        })
      )
      .nullable(),
    role: Yup.object()
      .shape({
        value: Yup.number(),
        label: Yup.string(),
      })
      .nullable()
      .required(t("validation.role")),
  })

  const editAdminSchema = Yup.object({
    username: Yup.string().required(t("validation.username")),
    email: Yup.string().required(t("validation.email")),
    language: Yup.string(),
    categories: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.number(),
          label: Yup.string(),
        })
      )
      .nullable(),
    role: Yup.object()
      .shape({
        value: Yup.number(),
        label: Yup.string(),
      })
      .nullable()
      .required(t("validation.role")),
  })

  const editProfileSchema = Yup.object({
    username: Yup.string().required(t("validation.username")),
    email: Yup.string().required(t("validation.email")),
    password: Yup.string().nullable(),
    img: Yup.string().nullable(),
  })


  const roleSchema = Yup.object({
    name: Yup.string().required(t("validation.name")),
    permissions: Yup.array()
      .of(Yup.number())
      .required(t("validation.permissions"))
      .min(1, t("validation.permissions")),
    types: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string(),
          label: Yup.string(),
        })
      )
      .nullable(),
  })


  const stageSchema = Yup.object({
    name: Yup.string().required(t("validation.NameInArabic")),
    name_en: Yup.string().required(t("validation.NameInEnglish")),
  })

  const taskSchema = Yup.object({
    text: Yup.string().required(t("validation.text")),
  })

  const folderSchema = Yup.object({
    name: Yup.string().required(t("validation.name")),
  })

  const creatorSchema = Yup.object({
    name: Yup.string().required(t("validation.name")),
    description: Yup.string().required(t("validation.bio")),
    image_url: Yup.string().required(t("validation.img")),
  })


  return {
    loginSchema,
    addAdminSchema,
    editAdminSchema,
    editProfileSchema,
    taskSchema,
    roleSchema,
  }

}




