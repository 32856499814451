import React, { useState } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import i18next from "i18next"

const LangSwitcher = props => {

    const [load, setLoad] = useState(false)
  return <div style={{width: "100px", margin: "auto"}}>
      {load&&<>load.....</>}
      <select className="form-control" value={i18next.language} onChange={async (e) => {
          setLoad(true)
          console.log('changing lang')
          try {
              await i18next.changeLanguage(e.target.value, (res) => {

              })
              setLoad(false)
          }catch (e) {
              setLoad(false)

          }

        }}>
        <option value={"ar"}>العربية</option>
        <option value={"en"}>English</option>
      </select>
    </div>
}

export default withRouter(LangSwitcher)
