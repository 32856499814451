

import { GoogleMap, Marker, useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import axios from "axios";
import React, { useEffect, useState } from "react";


export default function LocationInput({validation, fieldKey}){

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_MAPS_API,
        libraries: ["places"]
      })


      const [mapRef, setMapRef] = useState(null)

      const onLoad = function (ref) {
        setMapRef( ref)
      }

      const [inputVal, setInputVal] = useState("")

    return(
        <>
            {isLoaded && <Autocomplete
              onLoad={onLoad}
              onPlaceChanged={() => {
                const place = mapRef.getPlace().geometry.location
                validation.setFieldValue(fieldKey+"-long",place.lng())
                validation.setFieldValue(fieldKey+"-lat",place.lat())
                setInputVal("")
              }}
              
            >
                <input className="form-control" value={inputVal} onChange={(e) => setInputVal(e.target.value)} />
            </Autocomplete>}

            
           {isLoaded &&  <GoogleMap 
                mapContainerStyle={{
                    width: '100%',
                    height: '400px'
                }}
                zoom={10}
                center={validation.values[fieldKey+"-lat"] ? {lat: validation.values[fieldKey+"-lat"], lng: validation.values[fieldKey+"-long"]} : {lat: 30.04330573274441, lng: 31.221433222634705}}
                onClick={(e) => {
                    const loc = e.latLng
                    validation.setFieldValue(fieldKey+"-long",loc.lng())
                    validation.setFieldValue(fieldKey+"-lat",loc.lat())
                    console.log('map',e)
                }}
            >
                {validation.values[fieldKey+"-lat"] && <Marker position={{lat: validation.values[fieldKey+"-lat"], lng: validation.values[fieldKey+"-long"]}} />}
            </GoogleMap>}
        </>
    )
}