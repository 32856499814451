
export const MEDIA_FIELDS = [
  { key: "name", label: "Name" },

  { key: "description", label: "Description", type: "textarea" },
  { key: "image", label: "image", type: "file" },
  { key: "location", label: "location", type: "location" },

];

export const ADDITIONAL_FIELDS = [

];

const FIELDS = [...MEDIA_FIELDS, ...ADDITIONAL_FIELDS];

export default FIELDS;
