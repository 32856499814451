import React from "react"
//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import EventsTable from "./EventsTable"
import { useTranslation } from "react-i18next"

export default function EventsList() {

  const { t } = useTranslation()
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="" breadcrumbItem={t("Events")} />
        <EventsTable />
      </div>
    </div>
  )
}
