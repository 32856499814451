import i18next from "i18next";
import App from "./App";
import React from "react";


export default  function IndexContent(){


    const [language, setLanguage] = React.useState(i18next.language)
    i18next.on('languageChanged', (lng) => {
        setLanguage(lng)
    })
    return(
        <App key={language} />
    )
}