import React, { useEffect } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
// Formik validation
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { useHistory } from "react-router-dom";
import ResultHandler from "../../../components/ResultHandler";
import { addEvent } from "../../../api/events";
import FormButtonHandler from "../../../components/FormButtonHandler";
import { useResponse } from "../../../hooks/useResponse";
import { useTranslation } from "react-i18next";
import FIELDS from "./CONSTANT";
import EventForm from "./EventForm";
import * as Yup from "yup";

const TYPES = {
  select: null,
  "add-more": [],
  checkbox: false,
  file: null,
  date: null,
};

const initialValues = {};
const FIELDS_VALIDATION = {};

FIELDS.forEach((f) => {
  initialValues[f.key] = TYPES[f.type] || "";
  if (f.type == "file") {
    FIELDS_VALIDATION[f.key + "_preview"] = Yup.string().required(
      "This Field Is Required"
    );
  }else if (f.type == "location") {
   
  }else if (f.type == "select" && f.isMulti) {
    FIELDS_VALIDATION[f.key] = Yup.array();
  } else {
    FIELDS_VALIDATION[f.key] = Yup.string().required("This Field Is Required");
  }
});

const AddEvent = () => {
  const { t } = useTranslation();
  let navigate = useHistory();

  const { setData, loading, error, success } = useResponse(addEvent, () => {
    navigate.push("/events");
  });

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object(FIELDS_VALIDATION),
    onSubmit: (values) => {
      console.log(values)
      let data = new FormData();
      Object.keys(values).map((key) => {
        if (values[key]) {
          if(key.includes('location-')){
            data.append(key.replace('location-', ''), values[key]);
          }else{
            data.append(key, values[key]);
          }
        }
      });
      setData({ data, type: "add" });
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={t("Events")}
            breadcrumbItem={t("Add") + " " + t("Event")}
          />

          <Form
            className="needs-validation"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <EventForm validation={validation} />

            <FormButtonHandler btnTxt="Add" loading={loading} />

            <ResultHandler
              error={error}
              success={success}
              successMsg={"Created Successfully!"}
            />
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddEvent;
