import React, { useEffect, lazy } from "react"

import { Switch, BrowserRouter as Router, Route } from "react-router-dom"
import Pages404 from './components/PageNotFound'
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss

// import "./assets/scss/theme-rtl.scss"
// import './assets/scss/theme.scss'

////

import { RecoilRoot, useRecoilState } from "recoil"

import authState from "./atom/auth"
import i18next from "i18next"
import moment from "moment"
import {RtlStyle} from "./components/Common/RtlStyle";
import {LtrStyle} from "./components/Common/LtrStyle";
const App = ({}) => {
  const [auth, setAuth] = useRecoilState(authState)

  useEffect(() => {
    // import rtl if language is arabic
    if (auth) {
      document.documentElement.setAttribute(
        "lang",
          i18next.language
      )

    } else {
      document.documentElement.setAttribute("lang", i18next.language)
    }
  }, [auth])

  useEffect(() => {
    require(`./assets/scss/theme${i18next.language === "ar" ? "-rtl" : "" }.scss`)
    // localStorage.setItem("lang", i18next.language)
  }, [i18next.language])


  // useEffect(() => {
  //   if(!localStorage.getItem("lang")){
  //     localStorage.setItem("lang", 'en')
  //   }
  // }, [])

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              token={route.permission ? route.permission : null}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={VerticalLayout}
              component={route.component}
              key={idx}
              token={route.permission ? route.permission : null}
              isAuthProtected={true}
              exact
            />
          ))}

          <Route path="*" exact={true} component={Pages404} />
        </Switch>
      </Router>
    </React.Fragment>
  )
}

export default App
