import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form, Table,
} from "reactstrap"
// Formik validation
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useHistory, useParams } from "react-router-dom"
import { editRole, getRole } from "../../../api/roles"
import ResultHandler from "../../../components/ResultHandler"
import FormButtonHandler from "../../../components/FormButtonHandler"
import useCustomValidation from "../validationSchema"
import { useResponse } from "../../../hooks/useResponse"
import { useTranslation } from "react-i18next"
import { useGetInstence } from "../../../hooks/useGetInstence"
import RoleForm from "./RoleForm"





const EditRole = () => {
  const { t } = useTranslation()
  let navigate = useHistory()
  const { roleSchema } = useCustomValidation()

  const params = useParams()
  const [editedRole, setEditedRole] = useState(null)
  const { instenceLoading, instenceError, instenceSuccess } = useGetInstence(
    getRole,
    params.id,
    response => {

      setEditedRole({
        ...response.data.data.role,
        permissions: response.data.data.role.Permissions.map(p => p.id),
       
      })


    }
  )

  const { setData, loading, error, success } = useResponse(editRole, () => {
    navigate.push("/roles")
  })

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: editedRole ? editedRole.name : "",
      // editTextOnly: editedRole
      //   ? editedRole.settings.editTextOnly
      //     ? true
      //     : false
      //   : false,

      permissions: editedRole ? editedRole.Permissions.map(p => p.id) : [],
    },
    validationSchema: roleSchema,
    onSubmit: values => {
      let data = {
        ...values,
        // stages: stages.map(s => s.value),
        // settings: {
        //   types: values.types.map(t => t.value),
        //   editTextOnly: values.editTextOnly,
        // },
      }
      setData({ type: "edit", id: params.id, data })
    },
  })

  // get all permissions





  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={t("pages.Roles")}
            breadcrumbItem={t("btns.Edit") + " " + t("pages.Role")}
          />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  {editedRole && (
                    <Form
                      className="needs-validation"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                  
                      <RoleForm validation={validation}  />

                      <FormButtonHandler btnTxt="Edit" loading={loading} />
                    </Form>
                  )}

                  <ResultHandler
                    loading={instenceLoading}
                    error={error}
                    success={success}
                    successMsg={"Updated Successfully!"}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditRole
