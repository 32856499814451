import authState from "../atom/auth"
import i18next from "i18next"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useRecoilState } from "recoil"

function get_cookie(name) {
  return document.cookie.split(";").some(c => {
    return c.trim().startsWith(name + "=")
  })
}

function delete_cookie(name, path, domain) {
  if (get_cookie(name)) {
    document.cookie =
      name +
      "=" +
      (path ? ";path=" + path : "") +
      (domain ? ";domain=" + domain : "") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT"
  }
}

export const useLogout = () => {
  const [logout, setLogout] = useState(null)
  const history = useHistory()
  const [isLogged, setIsLogged] = useRecoilState(authState)

  useEffect(() => {
    if (logout) {
      localStorage.setItem("token", "")
      localStorage.setItem("user", "")
      localStorage.setItem("permissions", "")
      localStorage.setItem("toStages", "")
      localStorage.setItem("fromStages", "")
      localStorage.setItem("assetTableColumns", "")
      delete_cookie(
        process.env.REACT_APP_COOKIE_PERIFIX + "_token",
        "/",
        process.env.REACT_APP_DOMAIN
      )
      setIsLogged(false)
      setLogout(false)
      history.push("/login") 
    }
  }, [logout])

  return { setLogout }
}
