import { Button, Col, Input, Label, Row } from "reactstrap";


export default function AddMoreInput({label, values, onChangeValues, disabled}){


    return(
        <div>

            <Row className="mb-1">
                <Col md={10}>
                    <Label>{label} </Label>
                </Col>
                <Col md={2} style={{textAlign: "right"}}>
                    <Button disabled={disabled} type="button" color="primary" onClick={() => {
                        onChangeValues([...values, 'New value'])
                    }}>
                        <i className="bx bx-plus"></i>
                    </Button>
                </Col>
            </Row>

            <div>
                {values.map((v, i) => (
                    <Row key={i} className="d-flex mb-1">
                        <Col md={10}>
                            <Input disabled={disabled} type="text" value={v} onChange={(e) => onChangeValues(values.map((v, vI) => {
                                if(i === vI){
                                    return e.target.value
                                }else{
                                    return v
                                }
                            }))} />
                        </Col>
                        <Col md={2} style={{textAlign: "right"}}>
                            <Button disabled={disabled} color="danger" type="button" onClick={() => onChangeValues(values.filter((v,vI) => vI != i))}>
                                <i className="bx bx-minus"></i>
                            </Button>
                        </Col>
                    </Row>
                ))}
            </div>

        </div>
    )

}