import React, { useEffect, useState } from "react";

import { Card, CardBody, Button, Modal, ModalBody } from 'reactstrap';
import Dropzone, { useDropzone } from 'react-dropzone';
import { useResponse } from "../hooks/useResponse";
import { importFile } from '../api/assets';
import ResultHandler from './ResultHandler';
import {t} from "i18next";

export default function ImportModal({ isOpen, onCloseClick, api, handleRefresh, fileName }) {

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

    const { loading, error, success, setData:setPayload } = useResponse(api,(data)=>{
        handleRefresh()
        onCloseClick()
    });

    function handleUpload() {
        const data = new FormData();
        data.append('file', acceptedFiles[0]);
        setPayload({ type: "add", data });
    }

    return (
        <Modal isOpen={isOpen} toggle={onCloseClick} centered={true}>
        <ModalBody className="py-5 px-5">
                <h4 className='mb-4' style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                    <span style={{ display: "flex", alignItems: "center" }}>
                        {t("tables.Import")}
                    </span>
                    <div className='d-flex'>
                        {acceptedFiles.length == 0 && !loading && <Button style={{ marginLeft: "10px" }} color='primary'>
                            
                            <a style={{ color: "white", textDecoration: "none", }} href={process.env.PUBLIC_URL + fileName} download="template.xlsx">
                            Download Template
                            </a>
                        </Button>}
                        {acceptedFiles.length > 0 && !loading && <Button style={{ marginLeft: "10px" }} onClick={handleUpload} color='primary'>
                            Import
                        </Button>}
                        {acceptedFiles.length > 0 && loading && <Button style={{ marginLeft: "10px" }} color='dark'>
                            Uploading
                        </Button>}
                        {(success || error) && <Button onClick={onCloseClick} style={{ marginLeft: "10px" }}>
                            Cancel
                        </Button>}
                    </div>
                </h4>
                <ResultHandler error={error} />
                <section className="container" style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 0
                }}>
                    <div {...getRootProps({ className: 'dropzone' })} style={{width:"100%"}}>
                        <input {...getInputProps()} />
                        {acceptedFiles.length === 0 && <p className='mb-0' style={{ fontWeight: "bold", fontSize: "17px" ,textAlign:"center",padding:"10px",paddingTop:"50px",height:"100%"}}>
                            <i className="bx bx-cloud-upload" style={{ display: "block", margin: "auto", marginBottom: "15px" ,fontSize:"50px"}}></i>
                            Import File You Want
                        </p>}
                        {acceptedFiles.length > 0 && <p className='mb-0' style={{ fontWeight: "bold", fontSize: "17px" ,textAlign:"center",padding:"10px",paddingTop:"50px",height:"100%"}}>
                            <i className='bx bxs-check-circle' style={{ display: "block", margin: "auto", marginBottom: "15px",fontSize:"50px"}}></i>
                            {acceptedFiles[0].name}
                        </p>}
                    </div>
                </section>
            </ModalBody>
        </Modal>
    );
}
