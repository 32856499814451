
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
} from "reactstrap"

import React from "react"
import FIELDS, { MEDIA_FIELDS } from "./CONSTANT"
import { FieldInput } from "../../../components/FieldInput"




export default function EventForm({validation}){


    return(
        <Row>
            <Col md={12}>
                <Card>
                    <CardBody>
                        <Row>
                            {MEDIA_FIELDS.map(f => (
                                <FieldInput key={f.key} f={f} validation={validation} />
                            ))}
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            
        </Row>
    )
}