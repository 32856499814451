import { Button, Col, Input, Label, Row } from "reactstrap";
import { useTranslation } from "react-i18next"
import moment from "moment";

export default function AddMoreInputV2({ label, values, onChangeValue, disabled, schema, handleAddNewValue, handleRemovePair }) {
    const { t } = useTranslation()

    return (
        <div>
            <Row className="mb-1">
                <Col md={10}>
                    <Label>{label} </Label>
                </Col>
                <Col md={2} style={{ textAlign: "right" }}>
                    <Button disabled={disabled} type="button" color="primary" onClick={() => handleAddNewValue()}>
                        <i className="bx bx-plus"></i>
                    </Button>
                </Col>
            </Row>

            <div>
                {values.map((v, pairI) => (
                    <Row key={pairI} className="d-flex mb-1">
                        {
                             Object.keys(schema).map((key, i) => {
                                return <Col md={Math.round(12/Object.keys(schema).length)} key={key} className="mb-2">
                                    <Label>
                                        {i == 0 &&  
                                            <i onClick={() => handleRemovePair(pairI)} className="bx bx-minus" style={{marginRight: "10px", cursor: "pointer"}}></i>
                                        }
                                        {key}
                                    </Label>
                                    <Input 
                                        disabled={disabled} 
                                        type={schema[key] ? (schema[key]?.type || 'text') : "text"} 
                                        onChange={(e) => onChangeValue(e.target.value, pairI, key)} 
                                        value={schema[key]?.type === 'date' ? (v[key]?moment(v[key]).format('YYYY-MM-DD'):null) : (v[key] || "")}
                                    />
                                </Col>
                            })
                        }
                    </Row>
                ))}
            </div>

        </div>
    )

}