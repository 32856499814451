import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";

import React from "react";
import SearchSelectBox from "./SearchSelectBox";
import { useTranslation } from "react-i18next";
import AddMoreInput from "./AddMoreInput";
import AddMoreInputV2 from "./AddMoreInputV2";
import moment from "moment";
import ChooseImage from "./ChooseImage";
import LocationInput from "./LocationInput";




function formatLabel(key) {
  return key.split("_").join(" ");
}

export const FieldInput = ({ f, validation }) => {
  const { t } = useTranslation();


  return (
    <Col key={f.key} md={f.width || 6}>
      <FormGroup>
        {(!f.type ||
          ["number", "date", "time", "textarea", "email"].includes(f.type)) && (
          <>
            <Label>{formatLabel(f.label)}</Label>
            <Input
              name={f.key}
              placeholder={formatLabel(f.label)}
              type={f.type || "text"}
              className="form-control"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={
                f.type === "date"
                  ? validation.values[f.key]
                    ? moment(validation.values[f.key]).format("YYYY-MM-DD")
                    : null
                  : validation.values[f.key] || ""
              }
              invalid={validation.touched[f.key] && validation.errors[f.key]}
            />
          </>
        )}
        {f.type == "checkbox" && (
          <div className="d-flex" style={{ alignItems: "center" }}>
            <input
              name={f.key}
              placeholder={formatLabel(f.label)}
              type={"checkbox"}
              onChange={() => {
                if (validation.values[f.key]) {
                  validation.setFieldValue(f.key, false);
                } else {
                  validation.setFieldValue(f.key, true);
                }
              }}
              checked={validation.values[f.key] || false}
            />
            <Label style={{ margin: "0px 10px" }}>{formatLabel(f.label)}</Label>
          </div>
        )}
        {f.type === "select" && (
          <>
            <Label>{formatLabel(f.label)}</Label>
            <SearchSelectBox
              name={f.key}
              api={f.api}
              type={f.apiResKey}
              label={"name"}
              setValue={(e) => {
                if (f.isMulti) {
                  console.log(e);
                  validation.setFieldValue(
                    f.key,
                    e.map((a) => a.value)
                  );
                } else {
                  validation.setFieldValue(f.key, e?.value ? e.value : "");
                }
              }}
              value={validation.values[f.key] || null}
              error={validation.touched[f.key] && validation.errors[f.key]}
              isMulti={f.isMulti}
            />
          </>
        )}
        {f.type == "fixed-select" && (
          <>
            <Label>{formatLabel(f.label)}</Label>
            <select
              name={f.key}
              onChange={validation.handleChange}
              style={{ appearance: "auto" }}
              className="form-control"
            >
              <option></option>
              {f.options.map((op) => (
                <option key={op.value} value={op.value}>
                  {op.label}
                </option>
              ))}
            </select>
          </>
        )}
        {f.type == "add-more" && (
          <AddMoreInput
            label={t("forms." + f.label)}
            values={validation.values[f.key] || ["New value"]}
            onChangeValues={(vals) => {
              validation.setFieldValue(f.key, vals);
            }}
          />
        )}
        {f.type == "add-more-v2" && (
          <AddMoreInputV2
            label={f.label}
            values={validation.values[f.key] || []}
            onChangeValue={(val, i, key) => {
              validation.setFieldValue(
                f.key,
                validation.values[f.key].map((pair, pairI) => {
                  if (pairI == i) {
                    return { ...pair, [key]: val };
                  } else {
                    return pair;
                  }
                })
              );
            }}
            schema={f.schema}
            handleAddNewValue={() => {
              const data = {};
              Object.keys(f.schema).forEach((k) => {
                data[k] = "";
              });
              validation.setFieldValue(f.key, [
                ...(validation.values[f.key] || []),
                data,
              ]);
            }}
            handleRemovePair={(i) => {
              validation.setFieldValue(
                f.key,
                validation.values[f.key].filter((pair, pairI) => pairI != i)
              );
            }}
          />
        )}
       
        {f.type == "file" && (
          <>
            <ChooseImage
              error={validation.errors[f.key + "_preview"]}
              handleChooseImage={(file) => {
                validation.setFieldValue(f.key, file);
                if (file) {
                  validation.setFieldValue(
                    f.key + "_preview",
                    URL.createObjectURL(file)
                  );
                } else {
                  validation.setFieldValue(f.key + "_preview", "");
                }
              }}
              image={validation.values[f.key + "_preview"]}
              label={formatLabel(f.label)}
            />
          </>
        )}

        {f.type == "location" &&
          <>
            <Label>{formatLabel(f.label)}</Label>
            <LocationInput validation={validation} fieldKey={f.key} />
          </>
        }

        {validation.touched[f.key] && validation.errors[f.key] ? (
          <FormFeedback type="invalid">{validation.errors[f.key]}</FormFeedback>
        ) : null}
      </FormGroup>
    </Col>
  );
};
