import React, { useEffect } from "react"
// Layout Related Components
import Header from "./Header"
import Sidebar from "./Sidebar"
import Footer from "./Footer"

import { myAxios } from "../../api/axios"
import { useLogout } from "../../hooks/useLogout"

const Layout = props => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // notifications




  const { setLogout } = useLogout()

  myAxios.interceptors.request.use(config => {
    config.headers["Authorization"] = localStorage.getItem("token")
    return config
  })

  myAxios.interceptors.response.use(
    response => {
      return response
    },
    err => {
      if (err.response?.status === 401) {
        setLogout(true)
        document.location.reload()
      } else {
        throw err
      }
    }
  )

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header />
        <Sidebar isMobile={isMobile} />
        <div className="main-content" style={{ paddingTop: "25px" }}>
          {props.children}
        </div>
        <Footer />
      </div>
    </React.Fragment>
  )
}

export default Layout
