import React, { useEffect, useState } from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    Container,
    FormFeedback,
    Form,
} from "reactstrap"
// Formik validation
import { useFormik } from "formik"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useHistory, useParams } from "react-router-dom"
import ResultHandler from "../../../components/ResultHandler"
import { editEvent, getEvent } from "../../../api/events"
import FormButtonHandler from "../../../components/FormButtonHandler"
import useCustomValidation from "../validationSchema"
import { useResponse } from "../../../hooks/useResponse"
import { useTranslation } from "react-i18next"
import { useGetInstence } from "../../../hooks/useGetInstence"
import EventForm from "./EventForm"
import FIELDS from "./CONSTANT"
import * as Yup from "yup"

const TYPES = {
    'select': null,
    'add-more': [],
    'checkbox': false,
    'file' : null
}

const initialValues = {}
const FIELDS_VALIDATION = {}

FIELDS.forEach(f => {
    
    if(f.type != 'location'){
        initialValues[f.key] = TYPES[f.type] ? TYPES[f.type] : "" 

    }

    
    if(f.type == 'file'){
        FIELDS_VALIDATION[f.key+"_preview"] = Yup.string().required("This Field Is Required")

    }else if(f.type == "location"){

        
    }else{
        FIELDS_VALIDATION[f.key] = Yup.string().required("This Field Is Required")
    }
})

const EditEvent = () => {
    let navigate = useHistory()
    const { eventSchema } = useCustomValidation()

    const { t } = useTranslation()

    const params = useParams()
    const [editedEvent, setEditedEvent] = useState(null)

    const { instenceLoading, instenceError, instenceSuccess } = useGetInstence(
        getEvent,
        params.id,
        response => {
            const oldLat = response.data.data.event.lat
            const oldLong = response.data.data.event.long
            delete response.data.data.event.lat
            delete response.data.data.event.long
            setEditedEvent({
                ...response.data.data.event,
                image_preview: response.data.data.event.image_url,
                image: null,
                'location-lat': parseFloat(oldLat),
                'location-long': parseFloat(oldLong)
            })
        }
    )

    const { setData, loading, error, success } = useResponse(editEvent, () => {
        navigate.push("/events")
    })

    // Form validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            ...editedEvent,
        },
        validationSchema: Yup.object(FIELDS_VALIDATION),
        onSubmit: values => {
            let data = new FormData()
            Object.keys(values).map(key => {
                if(key.includes('location-')){
                    data.append(key.replace('location-', ''), values[key]);
                  }else{
                    data.append(key, values[key]);
                  }
            })
            setData({ type: "edit", id: params.id, data })
        },
    })

    console.log(validation.values)

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs
                        title={t("Events")}
                        breadcrumbItem={t("btns.Edit") + " " + t("Event")}
                    />
                    
                        {editedEvent && (
                            <Form
                                className="needs-validation"
                                onSubmit={e => {
                                    e.preventDefault()
                                    validation.handleSubmit()
                                    return false
                                }}
                            >
                                <EventForm validation={validation} />

                                <FormButtonHandler btnTxt="Edit" loading={loading} />
                            </Form>


                        )}

                        <ResultHandler
                            loading={instenceLoading}
                            error={error}
                            success={success}
                            successMsg={"Updated Successfully!"}
                        />
                                
                </Container>
            </div>
        </React.Fragment>
    )
}

export default EditEvent