import NonAuthLayout from "../components/NonAuthLayout"
import React from "react"
import { Route, Redirect } from "react-router-dom"
import Login from "../pages/Login"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  token,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (localStorage.getItem("user")) {
        if (token) {
          if (localStorage.getItem("permissions").includes(token)) {
            return (
              <Layout>
                <Component {...props} />
              </Layout>
            )
          } else {
            return (
              <Redirect
                to={{ pathname: "/home", state: { from: props.location } }}
              />
            )
          }
        } else {
          return (
            <Layout>
              <Component {...props} />
            </Layout>
          )
        }
      } else {
        if (props.location.pathname === "/login") {
          localStorage.removeItem('token')
          return (
            <NonAuthLayout>
              <Login />
            </NonAuthLayout>
          )
        } else {
          localStorage.removeItem('token')

          return (
            <NonAuthLayout>
            <Component {...props} />
          </NonAuthLayout>
          )
        }
      }
    }}
  />
)

export default Authmiddleware
