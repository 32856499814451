import { usePagination } from "../hooks/usePagination";
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import Select from "react-select";
import "./style.css";

function debounce(func, timeout = 500) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export default function SearchSelectBox(props) {
  const { t } = useTranslation();
  const [searchTxt, setSearchTxt] = useState("");
  const { response, setPayload, setMetaKey, meta } = usePagination(
    props.api,
    props.apiMeta
  );

  // useEffect(() => {
  //   if(searchTxt != null){
  //     processChange()
  //   }
  // }, [searchTxt])

  // const processChange =useCallback(debounce(() => setMetaKey('txt', searchTxt)),[]);

  const setSearch = useCallback(
    debounce((e) => setMetaKey("txt", e)),
    []
  );
  function getValue() {
    if (props.isMulti) {
      if (props.value) {
        return props.value.map((a) => ({
          label: (response?.data?.data || response.data)?.[props.type].find(
            (op) => op.id === a
          )?.[props.label],
          value: a,
        }));
      } else {
        return [];
      }
    } else {
      return props.value
        ? {
            label: (response?.data?.data || response.data)?.[props.type].find(
              (op) => op.id === props.value
            )?.[props.label],
            value: props?.value,
          }
        : null;
    }
  }
  return (
    <>
      {response.data && (
        <Select
          isDisabled={props.disabled}
          placeholder={t("forms.Select") + " ..."}
          value={getValue()}
          onChange={
            props.onChange ||
            ((data) => {
              props.setValue(data);
            })
          }
          isClearable={true}
          onFocus={props.handleFocus}
          onBlur={props.handleBlur}
          isMulti={props.isMulti}
          inputValue={searchTxt}
          onInputChange={(t) => {
            setSearchTxt(t);
            setSearch(t);
          }}
          options={(response.data.data || response.data)[props.type]
            .filter((c) => c.id != props.exclude)
            .map((c) => {
              return { label: c[props.label], value: c.id };
            })}
          className={props.error ? "invalid" : ""}
        />
      )}
      {props.error ? (
        <div
          style={{
            width: "100%",
            marginTop: "0.25rem",
            fontSize: "80%",
            color: "#f46a6a",
          }}
        >
          {props.error}
        </div>
      ) : null}
    </>
  );
}
